<template>
  <Toast />
  <div class="flex align-items-center py-5 px-0">
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center mb-5">
        <img
          src="images/coachera-logo-sm.png"
          alt="Image"
          height="50"
          class="mb-3"
        />
        <div class="text-900 text-3xl font-medium mb-3">Welcome</div>
      </div>

      <div>
        <div class="grid">
          <div class="col">
            <label for="first-name" class="block text-900 font-medium mb-2"
              >First Name</label
            >
            <InputText
              id="first-name"
              type="text"
              class="w-full mb-3"
              :class="{ 'p-invalid': v$.first_name.$invalid && submitted }"
              v-model="first_name"
            />
            <small
              v-if="
                (v$.first_name.$invalid && submitted) ||
                v$.first_name.$pending.$response
              "
              class="p-error"
              >{{
                v$.first_name.required.$message.replace("Value", "First name")
              }}</small
            >
          </div>
          <div class="col">
            <label for="last-name" class="block text-900 font-medium mb-2"
              >Last Name</label
            >
            <InputText
              id="last-name"
              type="text"
              class="w-full mb-3"
              :class="{ 'p-invalid': v$.last_name.$invalid && submitted }"
              v-model="last_name"
            />
            <small
              v-if="
                (v$.last_name.$invalid && submitted) ||
                v$.last_name.$pending.$response
              "
              class="p-error"
              >{{
                v$.last_name.required.$message.replace("Value", "Last name")
              }}</small
            >
          </div>
        </div>
        <label for="email" class="block text-900 font-medium mb-2"
          >E-Mail</label
        >
        <InputText
          id="email"
          type="text"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }"
          v-model="email"
        />
        <span
          v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
        >
          <span id="email-error">
            <small class="p-error">{{ v$.email.email.$message }}</small>
            <br />
            <small v-if="v$.email.required.$invalid" class="p-error">{{
              v$.email.required.$message.replace("Value", "Email")
            }}</small>
          </span>
        </span>

        <div class="flex flex-wrap">
          <div class="mr-4">
            <label for="country" class="block text-900 font-medium mb-2"
              >Country of residence</label
            >
            <AutoComplete
              forceSelection
              placeholder="Country"
              id="country"
              :dropdown="true"
              :multiple="false"
              v-model="selectedAutoValue"
              :suggestions="autoFilteredValue"
              @complete="searchCountry($event)"
              field="name"
            />
            <br />
            <br />
          </div>

          <div class="mr-4">
            <label for="pricerange" class="block text-900 font-medium mb-2"
              >Your price range</label
            >
            <Dropdown
              id="pricerange"
              v-model="dropdownValue"
              :options="dropdownValues"
              optionLabel="name"
              placeholder="Select price range"
            />
            <br />
            <br />
          </div>

          <div>
            <label
              for="preferred_coaching"
              class="block text-900 font-medium mb-2"
              >Type of preferred coaching</label
            >
            <Dropdown
              id="preferred_coaching"
              v-model="dropdownValue2"
              :options="dropdownValues2"
              optionLabel="name"
              placeholder="Select your type"
            />
            <!--<MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select Countries" :filter="true">
                <template #value="slotProps">
                    <div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2" v-for="option of slotProps.value" :key="option.code">
                        <span :class="'mr-2 flag flag-' + option.code.toLowerCase()" style="width:18px; height: 12px"/>
                        <div>{{option.name}}</div>
                    </div>
                    <template v-if="!slotProps.value || slotProps.value.length === 0">
                        <div class="p-1">Select Countries</div>
                    </template>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/>
                        <div>{{slotProps.option.name}}</div>
                    </div>
                </template>
            </MultiSelect>-->

            <br />
            <br />
          </div>
        </div>

        <label for="xlm_public_key" class="block text-900 font-medium mb-2"
          ><b>Optional</b>: Stellar (XLM) public key
          <Button
            icon="pi pi-question"
            class="p-button-rounded p-button-text"
            @click="open"
        /></label>
        <InputText
          id="xlm_public_key"
          type="text"
          class="w-full mb-3"
          v-model="xlm_public_key"
        />

        <Dialog
          header="The ludi token"
          v-model:visible="display"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '30vw' }"
          :modal="true"
        >
          <p class="line-height-3 m-0">
            As one of the very early users of the platform you are eligible for
            free ludi tokens. These tokens are based on the Stellar network. The
            more you interact with the platform, the more tokens you will get.
            Later you can redeem them for networking events or special events,
            or sell them on the open market.
          </p>
          <p>
            To receive tokens, you need a Stellar wallet. There are many wallets
            - one of the most user-friendly and popular wallets is
            <a href="https://lobstr.co/">Lobstr</a>. After creating a Lobstr
            account, you can view your Stellar address
            <a
              href="https://lobstr.zendesk.com/hc/en-us/articles/360000869413-Where-can-I-find-my-Stellar-wallet-address-"
              >here</a
            >. Paste that address in the textbox to get an airdrop of ludi
            tokens.
          </p>
          <template #footer>
            <Button
              label="Dismiss"
              @click="close"
              icon="pi pi-check"
              class="p-button-secondary"
            />
          </template>
        </Dialog>

        <label for="password" class="block text-900 font-medium mb-2"
          >Password</label
        >
        <!-- <InputText
          id="password"
          type="password"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
          v-model="password"
        />
        <small
          v-if="
            (v$.password.$invalid && submitted) ||
            v$.password.$pending.$response
          "
          class="p-error"
          >{{
            v$.password.required.$message.replace("Value", "Password")
          }}</small
        > -->
        <InputText
          id="password"
          type="password"
          v-model="v$.password.password.$model"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.password.password.$invalid && submitted }"
        />
        <small
          v-if="
            (v$.password.password.$invalid && submitted) ||
            v$.password.password.$pending.$response
          "
          class="p-error"
          >{{
            v$.password.password.required.$message.replace("Value", "Password")
          }}</small
        >
        <label for="confirmPassword" class="block text-900 font-medium mb-2"
          >Confirm password</label
        >
        <InputText
          id="confirmPassword"
          type="password"
          v-model="v$.password.confirm.$model"
          @keyup.enter="submit"
          class="w-full mb-3"
          :class="{
            'p-invalid': v$.password.confirm.$invalid && submitted,
            'border-green-400':
              !v$.password.confirm.$invalid && v$.password.confirm.$dirty,
          }"
        />
        <small
          v-if="v$.password.confirm.$invalid && v$.password.confirm.$dirty"
          class="p-error"
          >Passwords do not match.</small
        >
        <small
          v-else-if="
            !v$.password.confirm.$invalid && v$.password.confirm.$dirty
          "
          class="text-green-400"
          >Passwords are a match.</small
        >
        <div class="flex justify-content-center flex-wrap">
          <Button
            class="w-1/4 mr-2"
            @click="this.$router.go(-1)"
            icon="pi pi-arrow-left"
          >
          </Button>
          <Button
            label="Sign Up"
            icon="pi pi-user"
            class="w-1/4"
            v-on:click="submit(!v$.$invalid)"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryService from "../../service/CountryService";
import UserService from "../../service/UserService";
import { email, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  data() {
    return {
      display: false,
      v$: useVuelidate(),
      selectedAutoValue: null,
      autoFilteredValue: [],
      first_name: "",
      last_name: "",
      email: "",
      // password: "",
      password: {
        password: "",
        confirm: "",
      },
      submitted: false,
      dropdownValues: [
        { name: "<50$" },
        { name: "50-80$" },
        { name: "80-120$" },
        { name: "120-200$" },
        { name: "200-300$" },
        { name: "300$+" },
      ],
      dropdownValue: { name: "<50$" },
      xlm_public_key: "",
      dropdownValues2: [
        { name: "Group" },
        { name: "Subscription (3 months)" },
        { name: "Subscription (6 months)" },
        { name: "Subscription (>6 months)" },
        { name: "Individual Sessions" },
        { name: "Other" },
      ],
      dropdownValue2: { name: "Individual Sessions" },
    };
  },
  countryService: null,
  userService: null,
  created() {
    this.countryService = new CountryService();
    this.userService = new UserService();
  },
  mounted() {
    this.countryService.getCountries().then((data) => (this.autoValue = data));
  },
  validations() {
    return {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      // password: {
      //   required,
      // },
      password: {
        password: { required },
        confirm: { sameAsPassword: sameAs(this.password.password) },
      },
    };
  },
  methods: {
    searchCountry(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredValue = [...this.autoValue];
        } else {
          this.autoFilteredValue = this.autoValue.filter((country) => {
            return country.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 20);
    },
    open() {
      this.display = true;
    },
    close() {
      this.display = false;
    },
    submit(isFormValid) {
      this.submitted = true;
      if (this.v$.$invalid) {
        // alert("Failed");
        return;
      } else {
        // alert("success");
        var country = null;
        var pricerange = null;
        var preferred_coaching = null;
        if (this.selectedAutoValue && "name" in this.selectedAutoValue) {
          country = this.selectedAutoValue["name"];
        }
        if (this.dropdownValue && "name" in this.dropdownValue) {
          pricerange = this.dropdownValue["name"];
        }
        if (this.dropdownValue2 && "name" in this.dropdownValue2) {
          preferred_coaching = this.dropdownValue2["name"];
        }
        this.userService
          .createUser({
            name: this.first_name + " " + this.last_name,
            email: this.email,
            country: country,
            password: this.password["password"],
            pricerange: pricerange,
            preferred_coaching: preferred_coaching,
            coach: true,
            xlm_public_key: this.xlm_public_key,
          })
          .then((data) => {
            if ("error" in data) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data["error"],
                life: 3000,
              });
            } else {
              this.$router.push("/signupcoach/priorities");
            }
          });
      }
    },
  },
};
</script>
